<template>
  <div class="video"   v-if="data.resultData">


    <div class="videoDiv" :class="data.isFullScreen ? 'videoDiv-zindex' : ''">
      <video
        :ref="videoRef"
        id="videoPlayer"
        :controlslist="false"
        :webkit-playsinline="false"
        x-webkit-airplay="true"
        playsinline="true"
        x5-video-player-type="h5"
        x5-video-orientation="h5"
        x5-video-player-fullscreen="true"
        :poster="data.resultData.helloCityDail[0].contentThumbnailImage"
        preload="auto"
        loop
        style="width:100%;height:100%;object-fit: contain"


      >
        <source :src="data.resultData.helloCityDail[0].contentParticularsImage"/>
      </video>

      <img v-show="data.isFullScreen" @click="toSmallScreen" class="back-icon" src="../../assets/icon_back_video.png" alt="">
    </div>



    <button class="fullScreen" @click="toFullScreen">
      <img src="../../assets/icon_fullScreeen.png" alt="">
     <p>全屏播放</p>
    </button>


    <div class="bottom-desc">
      <p class="left-desc">
        {{data.resultData.helloCityDail[0].shareDescribe}}
      </p>
      <div class="right-detail">
        <img v-if="data.resultData.helloCityDail[0].productId && data.resultData.isGet == 0" class="share" src="@/assets/home/icon_digital_share.png" alt="" @click="toShareVideo">

        <div v-else @click="toShareVideo">
          <img  class="share-normal" src="@/assets/home/icon_video_share_normal.png" alt="">
          <p style="font-size: 12px;margin-top: -3px">分享</p>
        </div>

        <div class="like" @click="requestDz">
          <img v-if="data.resultData.isDZ == 1" class="like-icon" src="@/assets/home/icon_video_like.png" alt="">
          <img v-else class="like-icon" src="@/assets/home/icon_dz_white.png" alt="">
          <p>{{data.resultData.helloCityDail[0].likeNum}}</p>
        </div>

      </div>
    </div>


    <van-popup  v-model:show="data.showGetDigital"  >

      <div class="digital-success" >
        <img src="@/assets/home/icon_video_getSuccess.png" alt="">
        <button @click="toMyDigitalBadge"></button>
      </div>

    </van-popup>




  </div>
</template>
<script>

import {getCurrentInstance, nextTick, onBeforeUnmount, onMounted, onUnmounted, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import {clearToast, showFailToast, showLoading, showSuccessToast} from "../../utils/util";
import {shareInfo} from "../../api/user";
import {setTimeout} from "timers";
import home from '../new-home'

export default {
  name:"video-paly",
  components: {
  },
  setup() {

    const videoRef = ref(null);
    const { proxy } = getCurrentInstance();
    const $router = useRouter();
    const swipe = ref(null)
    const data = reactive({
      resultData:null,
      isRequestDz:false,
      showGetDigital:false,
      requestCount:0,
      isFullScreen:false,
      videoWidth:0,
      videoHeight:0
    })


    onMounted(()=>{

      setTimeout(()=>{
        let dom  =  document.getElementById('videoPlayer');
        data.videoWidth = dom.clientWidth;
        data.videoHeight = dom.clientHeight;
        document.getElementById('videoPlayer').play()
      },100)

      document.addEventListener("visibilitychange", funcGet);


    })

    onUnmounted(()=> {



      document.removeEventListener("visibilitychange", funcGet)
    })

    const funcGet = (hide) => {


      if(data.resultData.helloCityDail[0].productId && data.resultData.helloCityDail[0].productId != '' && data.resultData.isGet == 0 && document.hidden == false){


        getDigitalBadgeProduct();

      }

    }



    const loadData = () => {
      showLoading()
      proxy.$http.get("/wenwu-custom-services/wenwu/getHelloCityDail/"+$router.currentRoute.value.query.id).then(res => {
        clearToast()
        data.resultData = res.data.data;

      })
    }

    loadData()



    const requestDz = () => {
      if(data.isRequestDz) {
        return;
      }
      //点赞取消点赞
      data.isRequestDz = true;
      if(data.resultData.isDZ == 0){

        eshimin.bytedanceApplogEvent({
          eventName: 'like_click_button', // 例子
          eventParams: {'title_name': data.resultData.helloCityDail[0].title} ,//例子
          onResponse:  (res)=> {
            console.log("bytedanceApplogEvent responseData:" + res);
          }
        })

        let  params = {'hello_star_id':$router.currentRoute.value.query.id}
        proxy.$http.post("/wenwu-custom-services/wenwu/DZ",params).then(res => {

          data.isRequestDz = false;
          if(res.data.code == 200){
            clearToast()
            data.resultData.isDZ = 1;
            data.resultData.helloCityDail[0].likeNum += 1
          }else{
            showFailToast(res.data.message,1000);
          }
        }).catch((err)=>{
          data.isRequestDz = false;
          showFailToast(err.message,1000);
        })
      }else{
        let  params = {'hello_star_id':$router.currentRoute.value.query.id}
        proxy.$http.put('/wenwu-custom-services/wenwu/cancle',params).then(res => {

          data.isRequestDz = false;
          if(res.data.code == 200){
            clearToast()
            data.resultData.isDZ = 0;
            data.resultData.helloCityDail[0].likeNum -= 1
          }else{
            showFailToast(res.data.message,1000);
          }
        }).catch((err)=>{
          data.isRequestDz = false;
          showFailToast(err.message,1000);
        })
      }

    }

    //领取徽章
    const getDigitalBadgeProduct = () => {

        proxy.$http.post('/wenwu-user/order/showGetBadge',{'hello_star_id':$router.currentRoute.value.query.id}).then(res => {

          data.isRequestDz = false;
          if(res.data.code == 200){
            clearToast()
            data.showGetDigital = true;
            // getSckillProductWithId(res.data.data.productId)


          }else{
            showFailToast(res.data.message,1000);
          }
        }).catch((err)=>{

          showFailToast(err.message,1000);
        })
    }
    // const  getSckillProductWithId = (id) =>{
    //   proxy.$http.get("/wenwu-user/order/result?product_id=" + id).then(res2 => {

    //     if(res2.data.code ==  6003){

    //       if(data.requestCount < 5) {
    //         setTimeout(()=>{
    //           data.requestCount ++
    //           getSckillProductWithId(id)
    //         },500)
    //       }

    //     }else  if(res2.data.code ==  200){
    //       //调用支付接口，免费商品不会支付，只执行相关业务逻辑
    //       proxy.$http.post("/wenwu-user/pay/order", { orderId: res2.data.data, payType: 0 }).then((res3) => {

    //         if(res3.data.code == 200){
    //           console.log(111111111111);
    //           data.requestCount = 0;
    //           data.showGetDigital = true;
    //           clearToast()
    //         }
    //       })
    //     }

    //   })
    // };

    const toMyDigitalBadge = () => {
      $router.push('/digitalListPage')
    }


    const toShareVideo = () => {


      //埋点上报
      eshimin.bytedanceApplogEvent({
        eventName: 'share_click_button', // 例子
        eventParams: {'share_name': shareInfo.title, 'title_name': '视频详情页'} ,//例子
        onResponse:  (res)=> {
          console.log("bytedanceApplogEvent responseData:" + res);
        }
      })

      eshimin.share({
        version: 0,
        title: shareInfo.title,
        content:  shareInfo.desc,
        imgUrl:  shareInfo.logo,
        link: shareInfo.link,
        onResponse:  (res)=> {

          // console.log(999999999999999999);
          // console.log(res);
          // let codeJson =  JSON.parse(res);
          //
          // if(codeJson.code == 200 &&  data.resultData.helloCityDail[0].productId){//分享成功
          //
          // }
          // console.log("share responseData:" + res);
        }



      })




    }

    const toFullScreen = () => {
      data.isFullScreen = true;
      let ele = document.getElementById('videoPlayer')

      let cha = Math.abs(data.videoHeight - data.videoWidth) / 2;
      ele.style.width = data.videoHeight + 'px';
      ele.style.height = data.videoWidth  + 'px';
      ele.style.transform =  'translate(-' + cha + 'px,' + cha + 'px) rotate(90deg)'

    }

    const toSmallScreen = () => {
      data.isFullScreen = false;
      let ele = document.getElementById('videoPlayer')
      ele.style.width = data.videoWidth + 'px';
      ele.style.height = data.videoHeight  + 'px';
      ele.style.transform =  'unset'

    }






    return {
      toSmallScreen,

      videoRef,
      data,
      requestDz,
      toMyDigitalBadge,
      toShareVideo,
      toFullScreen



    }

  }

}
</script>
<style scoped lang="scss" src="./index.scss">
</style>

